<template>
    <div>
        <div class="border d-flex border-5 justify-content-around border-white" @dragover="dragover"
            @dragleave="dragleave" @drop="drop"
            style="border:  #F0EDF4;border-style:dashed!important ; width:100%; height: 230px;border-radius: 25px;border:  dashed 6px #F0EDF4 !important;">
            <div class="m-2 d-flex">
                <div class="pb-2">
                    <input type="file" name="file" id="fileInput"
                        style="width: 0;height: 0;position: absolute;opacity: 0;overflow: hidden;" @change="onChange();"
                        ref="file" accept=".png,.jpg,.jpeg,.pdf,.zip,.rar,.mp4" />
                </div>
                <b-row class="filter-row d-flex flex-column justify-content-center  align-items-center ">
                    <p v-if="isDragging" style="font-size: 40px;font-weight: 700;color: #BDAECF;">فایل را اینجا رها کنید</p>
                    <template v-else>
                        <label for="fileInput"
                            class="brand-logo p-1 justify-content-center bg-opacity-10 rounded-circle row cursor-pointer"
                            style="height: 95px; width: 100px;background: #33254C14;">
                            <img src="/icons/path.svg" style="width: 47px; height:60px;" />
                        </label>
                        <b-button
                            class="text-center d-flex justify-content-center align-items-center text-white fs-4 fw-bold font-family-iranSans m-0"
                            :variant="files.length == 0 || isLoading ? 'secondary' : 'primary'"
                            style="margin-top:16px !important;height:36px;width: 160px;font-size: 24px!important;font-weight: 700;"
                            :disabled="files.length == 0 || isLoading" @click="attachmentUpload()">
                            آپلود فایل
                            <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
                        </b-button>
                    </template>
                </b-row>
            </div>
        </div>
        <b-card-code style="display: flex; flex-direction: column;margin-top: 21px;" class="border-radius-25" no-title>
            <b-row style="margin-top: 21px;">
                <b-card-body class="pt-0 d-lg-flex d-none px-1">
                    <b-col cols="12">
                        <b-row class="d-flex  justify-content-center  align-items-center  px-5 pt-2" style="gap:15px">
                            <label class="text-center d-flex justify-content-center align-items-center "
                                style="font-weight:400;font-size:18px;color:#6D6D6D;padding-left: 25px;">فیلتر</label>
                            <b-col>
                                <v-select dir="rtl" v-model="filters.name" :options="filesOptions"
                                    :reduce="(item) => item.value" @search="searchAllFilesByName" placeholder="عنوان فایل"
                                    class=" border" style="border-radius: 5.712px; border-color: #d8d6de !important;">
                                    <span slot="no-options">موردی یافت نشد.</span>
                                </v-select>
                            </b-col>
                            <b-button class="d-flex justify-content-center align-items-center border-radius-25"
                                variant="primary" type="submit" :disabled="isLoadingTwo" @click="callGetAllFilesFiltered()">
                                <span class="text-nowrap" style="font-weight:700;font-size:16px;width: 160px;">اعمال فیلتر
                                    ها</span>
                                <b-spinner v-show="isLoadingTwo" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-row>
                    </b-col>
                </b-card-body>
            </b-row>
            <!-- mobile -->
            <b-card-body class="pt-0 d-flex flex-column  px-1 d-lg-none ">
                <b-col cols="12" class="">

                    <b-row class="d-flex  justify-content-between  align-items-center   pt-2" style="gap:24px">

                        <b-col cols="5">

                            <label class="text-right w-100 d-flex justify-content-start align-items-center p-0 "
                                style="font-weight:400;font-size:18px;color:#6D6D6D;">فیلتر</label>
                        </b-col>
                        <b-col cols="5" class="p-0">
                            <b-button class="d-flex w-100 justify-content-center align-items-center border-radius-25"
                                variant="primary" type="submit" :disabled="isLoadingTwo"
                                @click="callGetAllTicketsFiltered()">
                                <span class="text-nowrap " style="font-weight:700;font-size:16px;">اعمال فیلتر ها</span>
                                <b-spinner v-show="isLoadingTwo" class="position-absolute" small type="grow" />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12">
                    <b-row class="d-flex justify-content-between  align-items-center   pt-2">
                        <b-col cols="12 " class="p-0">
                            <b-form-input class="w-100 " placeholder="عنوان فایل" style="width: 144px;" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-card-body>
            <LoadingComponent v-if="isLoadingTwo" />
            <template v-else>
                <div class="pt-1 pb-1 overflow" v-if="filesList.length > 0">
                    <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="filesList" :rtl="false"
                        class="w-100">
                        <div slot="emptystate" class="w-100 text-center">موردی یافت نشد</div>
                        <template slot="table-row" slot-scope="props">
                            <!-- Column: Name -->
                            <span v-if="props.column.field === 'id'" class="text-nowrap">
                                <span class="text-nowrap">{{ props.row.id }}</span>
                            </span>
                            <span v-if="props.column.field === 'name'" class="text-nowrap">
                                <span class="text-nowrap">
                                    {{ props.row.name.length > 40 ? props.row.name.substring(0, 40) + "..." :
                                        !props.row.name
                                            ? '-' : props.row.name }}
                                </span>
                            </span>
                            <span v-if="props.column.field === 'size'" class="text-nowrap">
                                <span class="text-nowrap" dir="ltr">{{ (props.row.size / 1024 / 1024).toFixed(2) }}
                                    MB</span>
                            </span>
                            <span v-if="props.column.field === 'mime_type'" class="text-nowrap">
                                <span class="text-nowrap">{{ props.row.mime_type ? props.row.mime_type : '-' }}</span>
                            </span>
                            <span v-if="props.column.field === 'url'" class="text-nowrap">
                                <b-button @click="doCopy(props.row.url)" variant="primary" :disabled="!props.row.url">کپی
                                    کردن</b-button>
                            </span>
                            <!-- Column: Action -->
                            <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                                <span>
                                    <b-link class="w-100 d-flex justify-content-center "
                                        @click="deletePrompt(props.row.id)">
                                        <feather-icon icon="TrashIcon" />
                                    </b-link>
                                </span>
                            </span>
                        </template>
                    </vue-good-table>
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalFiles" :per-page="perPage" first-number
                        last-number prev-class="prev-item" next-class="next-item"
                        class="mt-1 mb-0 p-0 d-flex justify-content-center align-content-center " dir="ltr">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </template>
        </b-card-code>
    </div>
</template>
<script>
import debounce from "lodash/debounce";
import BCardCode from "@core/components/b-card-code";
import LoadingComponent from "@/components/LoadingComponent";
import axios from "axios";
import copy from 'copy-text-to-clipboard';
import {
    BModal,
    BCard,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        VueGoodTable,
        ToastificationContent,
        BCardCode,
        BButton,
        BRow,
        BCol,
        BSpinner,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCard,
        BCardHeader,
        BCardBody,
        vSelect,
        LoadingComponent,
    },
    data() {
        return {
            isDragging: false,
            files: [],
            file: '',
            isLoading: false,
            isLoadingTwo: false,
            perPage: 15,
            totalFiles: 0,
            currentPage: 1,
            filesList: [],
            columns: [
                {
                    label: "شناسه",
                    field: "id",
                },
                {
                    label: "نام فایل",
                    field: "name",
                },
                {
                    label: "حجم",
                    field: "size",
                },
                {
                    label: "فرمت",
                    field: "mime_type",
                },
                {
                    label: "لینک",
                    field: "url",
                },
                {
                    label: "حذف",
                    field: "action",
                },
            ],
            filters: {
                name: null,
            },
            filesOptions: [],
        };
    },
    methods: {
        onChange() {
            if (this.$refs.file.files[0].size / (1024 * 1024) > 100) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "اخطار",
                        icon: "XOctagonIcon",
                        text: "حجم فایل انتخاب شده بیش از حد مجاز است. (100MB)",
                    },
                });
            } else {
                this.files = [...this.$refs.file.files];
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        attachmentUpload() {
            this.isLoading = true
            const file = new FormData();
            if (this.files[0]) {
                file.append("file", this.files[0]);
                axios.post("/api/v1/admin/files/upload", file).then((response) => {
                    if (response.status == 201) {
                        this.files = []
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "فایل اضافه شد",
                                icon: "CheckIcon",
                                text: "فایل با موفقیت اضافه شد.",
                                variant: 'info',
                            },
                        });
                        this.isLoading = false
                        this.getAllFilesFiltered()
                    }
                }).catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "اخطار",
                            icon: "XOctagonIcon",
                            text: "فایل بارگذازی نشد. لطفا دوباره تلاش کنید.",
                            variant:"danger",
                        },
                    });
                    this.isLoading = false
                })
            }
        },
        getAllFilesFiltered() {
            let requestBody = {
                params: { page: this.currentPage },
            };

            if (this.filters.name) {
                if (this.filters.name != null) {
                    requestBody.params.name = this.filters.name;
                } else if (this.filters.name == null) {
                    delete requestBody.params.name;
                }
            }
            this.isLoadingTwo = true
            axios
                .get("/api/v1/admin/files", requestBody)
                .then((response) => {
                    this.isLoadingTwo = false
                    let itemsTray = response.data.data;
                    this.filesList = [];
                    this.totalFiles = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;

                    itemsTray.forEach((item) => {
                        this.filesList.push({
                            id: item.id,
                            name: item.name,
                            size: item.size,
                            mime_type: item.mime_type,
                            url: item.url,
                        });
                    });
                })
                .catch((error) => {
                    this.isLoadingTwo = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در لیست فایل ها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        callGetAllFilesFiltered() {
            if (this.currentPage == 1) {
                this.getAllFilesFiltered();
            } else {
                this.currentPage = 1;
            }
        },
        getAllFilesByName(name = null) {
            axios
                .get("/api/v1/admin/files", { params: { name: name == "" || name === " " ? null : name } })
                .then((response) => {
                    let FilesByNameTray = response.data.data;
                    this.filesOptions = [];

                    FilesByNameTray.forEach((item) => {
                        this.filesOptions.push({
                            label: item.id + " - " + item.name,
                            value: item.name,
                        });
                    });
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در فیلترها پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
        searchAllFilesByName: debounce(function (e) {
            this.getAllFilesByName(e);
        }, 500),
        doCopy(text) {
            copy(text);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "لینک دانلود در کلیپبورد کپی شد.",
                    icon: "CheckIcon",
                    text: "لینک دانلود با موفقیت در کلیپبورد کپی شد.",
                    variant: "success",
                },
            });
        },
        deletePrompt(id) {
            this.$bvModal
                .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
                    title: '',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'بله',
                    cancelTitle: 'خیر',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value == true) {
                        this.deleteFile(id)
                    }
                })
        },
        deleteFile(id) {
            axios
                .post(`/api/v1/admin/files/${id}/delete`)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "فایل حذف شد",
                            icon: "CheckIcon",
                            text: "فایل با موفیقت حذف شد.",
                            variant: "info",
                        },
                    });
                    this.getAllFilesFiltered();
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "خطا",
                            icon: "XOctagonIcon",
                            text: "مشکلی در عملیات حذف پیش آمده.",
                            variant: "danger",
                        },
                    });
                });
        },
    },
    mounted() {
        this.getAllFilesByName()
        this.getAllFilesFiltered()
        this.$watch("currentPage", (newCurrentPage) => {
            this.getAllFilesFiltered()
        });
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.filter-row {
    justify-content: space-between;
}
</style>